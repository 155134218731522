<template>
    <SiteModal v-if="openModal" :heading="$t('regions')" @close-modal="closeModal()">
        <template #content>
            <div class="topic-region-chips-content">
                <div v-for="(region, index) in allRegions" :key="index" class="chips-grouping">
                    <div class="grouping-wrapper">
                        <div class="region-chip">
                            <Chip :url="region.path+'?lang='+pageLanguage" @click="closeModal()">
                                {{ region.title }}
                            </Chip>
                        </div>
                        <div class="chips-wrapper">
                            <Chip
                                v-for="(subregion, subregionIndex) in region.countries"
                                :key="subregionIndex"
                                :url="subregion.path+'?lang='+pageLanguage"
                                theme="outline"
                                @click="closeModal()"
                            >
                                {{ subregion.title }}
                            </Chip>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </SiteModal>
</template>

<script setup>
const { pageLanguage } = usePageDataStore();
defineProps({
    openModal: {
        type: Boolean,
        default: false,
    },
    allRegions: {
        type: Object,
    },
    closeModal: {
        type: Function,
    },
});
</script>

<style lang="scss" scoped>
.chips-grouping {
    &:not(:last-of-type) {
        border-bottom: $border;
    }

    &:last-of-type {
        @include media-query(mobile) {
            padding-bottom: vertical-space(6);
        }
    }

    .grouping-wrapper {
        @include content-section;
        @include padding-tb(vertical-space(1));
        display: flex;
        align-items: flex-start;
        gap: 3rem;

        @include media-query(phone) {
            flex-direction: column;
        }
    }

    .region-chip {
        max-width: 32rem;
        width: 100%;
        flex-shrink: 0;
        @include padding-tb(vertical-space(1));
    }

    .chips-wrapper {
        display: flex;
        gap: calc(var(--col-gutter) / 2);
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
}
</style>

<template>
    <SiteModal v-if="openModal" :heading="$t('topics')" @close-modal="closeModal()">
        <template #content>
            <div class="topic-region-chips-content">
                <div v-for="(letterSet, index) in allTopics" :key="index" class="chips-grouping">
                    <div class="grouping-wrapper">
                        <Typography variant="label">{{ letterSet[0] }}</Typography>
                        <div class="chips-wrapper">
                            <Chip
                                v-for="(topic, topicIndex) in letterSet[1]"
                                :key="topicIndex"
                                :url="topic.path+'?lang='+pageLanguage"
                                theme="outline"
                                @click="closeModal()"
                            >
                                {{ topic.title }}
                            </Chip>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </SiteModal>
</template>

<script setup>
const { pageLanguage } = usePageDataStore();
defineProps({
    openModal: {
        type: Boolean,
        default: false,
    },
    allTopics: {
        type: Object,
    },
    closeModal: {
        type: Function,
    },
});
</script>

<style lang="scss" scoped>
.chips-grouping {
    &:not(:last-of-type) {
        border-bottom: $border;
    }

    &:last-of-type {
        @include media-query(mobile) {
            padding-bottom: vertical-space(6);
        }
    }

    .grouping-wrapper {
        @include content-section;
        @include padding-tb(vertical-space(1));
        display: flex;
        align-items: center;
        gap: 3rem;
    }

    .chips-wrapper {
        display: flex;
        gap: calc(var(--col-gutter) / 2);
        align-items: center;
        justify-content: flex-start;
    }
}
</style>
